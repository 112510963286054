import Vue from "vue"
import axios from "axios"
import VueAxios from "vue-axios"
import TokenHelper from "@/services/token.helper"
import AuthService from "@/services/auth.helper"
import store from '@/store'
import LangHelper from "@/services/lang.helper"

const ApiService = {
  init(router) {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL
    // Vue.axios.defaults.withCredentials = true
    Vue.axios.interceptors.request.use((config) => {
      // if(config.method === 'post' && config.headers['Content-Type'] !== "multipart/form-data") {
      //   config.headers['Content-Type'] = `application/x-www-form-urlencoded`;
      // }
      // if (config.headers['Content-Type'] && config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
      //   config.transformRequest = (data) => {
      //     const str = [];
      //     Object.keys(data).forEach(key => str.push(`${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`));
      //     return str.join('&');
      //   }
      // }
      // config.headers['Accept-Language'] = LangHelper.getLowcaseLanguage()
      // if(config.url  !== "v2/oauth/token") {

      // config.headers['Content-Type'] = 'application/json'
      config.headers['apitoken'] = process.env.VUE_APP_API_TOKEN
      config.headers['language'] = LangHelper.getLowcaseLanguage()
      const token = TokenHelper.getAccessToken()
      // console.log("api call to "+config.url)
      // console.log(token)
      if (token) {
        // console.log(token.access_token)
        config.headers['Authorization'] = `Bearer ${token.access_token}`
        // config.headers['withCredentials'] = 'true';
      }
      // }
      let loader = config.loader

      store.state.loading = typeof loader !== 'undefined' ? loader : true
      // console.log(config)
      return config
    }, error => Promise.reject(error))



    Vue.axios.interceptors.response.use((response) => {
      store.state.loading = false
      return response
    }, function (error) {
      store.state.loading = false
      let status = error.response ? error.response.status : 400
      // console.log('status', error.response)
      // console.log('status', status)
      if(status === 426) {
        AuthService.logout()
        router.push('/login')
      }
      return Promise.reject(error)
    })
  },

  // query(resource, params) {
  //   return Vue.axios.get(resource, params).catch(error => {
  //     throw new Error(`[RWV] ApiService ${error}`);
  //   });
  // },

  get(resource, params = {}, headers = {}) {
    let p = { params: params }
    p = {...p, ...headers }
    return Vue.axios.get(resource, p)
  },

  post(resource, params, headers = {}, config = {}) {
    headers['Accept'] = 'application/json'
    config.headers = headers
    return Vue.axios.post(`${resource}`, params, config)
  },

  delete(resource, headers = {}, config = {}) {
    headers['Accept'] = 'application/json'
    config.headers = headers
    return Vue.axios.delete(`${resource}`, config)
  },
};

export default ApiService;


export const LessonsService = {
  get(students = [], groups = [], teacher_id, day = null, allteachers = false) {
    return ApiService.get("instructor/lessons/get", { students: students, groups: groups, teacher_id: teacher_id, day: day, allteachers: allteachers })
  },
  add(params) {
    return ApiService.post("instructor/lessons/add", params)
  },
  update(id, params) {
    return ApiService.post("instructor/lessons/"+id+"/update", params)
  },
  delete(id) {
    return ApiService.delete("instructor/lessons/"+id+"/delete")
  },
  getSlidesForLesson(id, code, homework_student_id) {
    return ApiService.get("general/lessons/"+id+"/"+code+"/slides"+(homework_student_id ? "/"+homework_student_id : ""))
  },
  getStudentsForLesson(id, code) {
    return ApiService.get("general/lessons/"+id+"/"+code+"/students")
  },
  getSlidesForMaterial(mid) {
    return ApiService.get("general/material/"+mid+"/slides/")
  },
  sendAttendance(id, params) {
    return ApiService.post("instructor/lessons/"+id+"/attendance", params)
  },
  used(id) {
    return ApiService.post("instructor/lessons/"+id+"/used")
  },
  saveTaskStorage(params) {
    return ApiService.post("call/task/storage/save", params, {}, { loader: false })
  },
  saveLightTaskStorageBulk(params) {
    return ApiService.post("call/task/storage/save/light/bulk", params, {}, { loader: false })
  },
  saveTaskStorageThroughQR(params) {
    return ApiService.post("general/task/storage/qr/save", params)
  },
  retrieveTaskStorage(lesson_or_material_id, type = 'lesson', user_id = 'self') {
    return ApiService.get(`call/task/storage/get/${lesson_or_material_id}/${type}/${user_id}`)
  },
  getResponsesForBlockForAllStudents(lesson_id, block_id, type = 'lesson', studentId = null) {
    return ApiService.get(`call/task/storage/allStudents/${lesson_id}/${block_id}`, { type, studentId })
  },
  generateLinkchatLink(lessonId) {
    return ApiService.post(`call/linkchat/link`, { lessonId })
  },
  getBlackboard(lessonId) {
    return ApiService.get(`call/blackboard/${lessonId}`)
  },
  saveBlackboard(lessonId, data) {
    return ApiService.post(`call/blackboard/${lessonId}`, { json: data }, {}, { loader: false })
  },
  addUserToClass(lessonId, userId) {
    return ApiService.post(`general/lessons/${lessonId}/user/${userId}/add`, {}, {}, { loader: false })
  },
  removeUserFromClass(lessonId, userId) {
    return ApiService.post(`general/lessons/${lessonId}/user/${userId}/remove`, {}, {}, { loader: false })
  },
  checkIfIAmInClass(lessonId, userId) {
    return ApiService.post(`general/lessons/${lessonId}/user/${userId}/check`, {}, {}, { loader: false })
  }
}


export const CoursesService = {
  get() {
    return ApiService.get("instructor/courses/get")
  },
  add(params) {
    return ApiService.post("instructor/courses/add", params, {'Content-Type': 'multipart/form-data'})
  },
  update(id, params) {
    return ApiService.post("instructor/courses/"+id+"/update", params, {'Content-Type': 'multipart/form-data'})
  },
  resort(id, params) {
    return ApiService.post("instructor/courses/"+id+"/resort/", params, {}, { loader: false })
  },
  assign(id, params) {
    return ApiService.post("instructor/courses/"+id+"/assign", params, {}, { loader: false })
  },
  delete(id) {
    return ApiService.delete("instructor/courses/"+id+"/delete")
  },
  getLightCourses(loader = true, courseId = '', moduleId = '', lessonId = '') {
    return ApiService.get(`instructor/lightcourses/get?courseId=${courseId}&moduleId=${moduleId}&lessonId=${lessonId}`, {}, { loader: loader })
  },
  addLight(params) {
    return ApiService.post("instructor/lightcourses/add", params, { 'Content-Type': 'multipart/form-data' })
  },
  updateLight(id, params) {
    return ApiService.post("instructor/lightcourses/"+id+"/update", params, { 'Content-Type': 'multipart/form-data' })
  },
  deleteLight(model, id) {
    return ApiService.delete("instructor/lightcourses/"+model+"/"+id+"/delete")
  },

}


export const MaterialsService = {
  get(course_id, material_id) {
    return ApiService.get("instructor/courses/"+course_id+"/materials/"+material_id)
  },
  getLightCourseMaterial(material_id) {
    return ApiService.get("instructor/lightcourses/materials/"+material_id)
  },
  add(id, params) {
    return ApiService.post("instructor/courses/"+id+"/materials/add", params, {'Content-Type': 'multipart/form-data'})
  },
  update(course_id, material_id, params) {
    return ApiService.post("instructor/courses/"+course_id+"/materials/"+material_id+"/update", params, {'Content-Type': 'multipart/form-data'})
  },
  delete(course_id, material_id) {
    return ApiService.delete("instructor/courses/"+course_id+"/materials/"+material_id+"/delete")
  },
  resort(course_id, material_id, params) {
    return ApiService.post("instructor/courses/"+course_id+"/materials/"+material_id+"/resort",
        params, {}, { loader: false })
  },
  addAudio(course_id, material_id, params) {
    return ApiService.post("instructor/courses/"+course_id+"/materials/"+material_id+"/audio/add", params, {'Content-Type': 'multipart/form-data'})
  },
  addPresentation(course_id, material_id, params) {
    return ApiService.post("instructor/courses/"+course_id+"/materials/"+material_id+"/presentation/add", params, {'Content-Type': 'multipart/form-data'})
  },
  deleteAudio(course_id, material_id, path) {
    return ApiService.post("instructor/courses/"+course_id+"/materials/"+material_id+"/audio/delete", { path: path })
  },
  deletePresentation(course_id, material_id, path) {
    return ApiService.post("instructor/courses/"+course_id+"/materials/"+material_id+"/presentation/delete", { path: path })
  },
}



export const SlidesService = {
  get(course_id, material_id, slide_id) {
    return ApiService.get("instructor/courses/"+course_id+"/materials/"+material_id+"/slides/"+slide_id)
  },
  add(course_id, material_id, params) {
    return ApiService.post("instructor/courses/"+course_id+"/materials/"+material_id+"/slides/add", params)
  },
  update(course_id, material_id, slide_id, params) {
    return ApiService.post("instructor/courses/"+course_id+"/materials/"+material_id+"/slides/"+slide_id+"/update", params)
  },
  delete(course_id, material_id, slide_id) {
    return ApiService.delete("instructor/courses/"+course_id+"/materials/"+material_id+"/slides/"+slide_id+"/delete")
  },
}



export const ConstructorService = {
  getSlideForConstructor(id) {
    return ApiService.get("instructor/constructor/slide/"+id)
  },
  addBlock(slide_id, type_id, index) {
    return ApiService.post("instructor/constructor/slide/"+slide_id+"/block/add", { type_id: type_id, index: index })
  },
  removeBlock(slide_id, block_id) {
    return ApiService.delete("instructor/constructor/slide/"+slide_id+"/block/"+block_id+"/delete")
  },
  removeUnsavedBlocks(slide_id, loader = false) {
    return ApiService.delete("instructor/constructor/slide/"+slide_id+"/unsaved/delete", {}, { loader: loader })
  },
  updateBlock(slide_id, block_id, params) {
    return ApiService.post("instructor/constructor/slide/"+slide_id+"/block/"+block_id+"/update", params, {'Content-Type': 'multipart/form-data'})
  },
  hideBlock(slide_id, block_id) {
    return ApiService.post("instructor/constructor/slide/"+slide_id+"/block/"+block_id+"/hide", { val: 1 })
  },
  unhideBlock(slide_id, block_id) {
    return ApiService.post("instructor/constructor/slide/"+slide_id+"/block/"+block_id+"/hide", { val: 0 })
  },
  resortBlocks(slide_id, blocks) {
    return ApiService.post("instructor/constructor/slide/"+slide_id+"/block/resort", { blocks: blocks })
  },
  copyBlockToSlide(slide_id, block_id, newslide_id) {
    return ApiService.post("instructor/constructor/slide/"+slide_id+"/block/"+block_id+"/copy/"+newslide_id)
  },
  saveAudio(slide_id, block_id, params) {
    return ApiService.post("call/constructor/slide/"+slide_id+"/block/"+block_id+"/audio", params, {'Content-Type': 'multipart/form-data'})
  },
  getImages(slide_id, block_id, user_id, id, mode) {
    return ApiService.get("general/constructor/slide/"+slide_id+"/block/"+block_id+"/images/"+user_id+"/"+mode+"/"+id)
  },
  saveImages(slide_id, block_id, user_id, id, mode, params) {
    return ApiService.post("general/constructor/slide/"+slide_id+"/block/"+block_id+"/images/"+user_id+"/"+mode+"/"+id, params, {'Content-Type': 'multipart/form-data'})
  },
  deleteImage(slide_id, block_id, user_id, id, mode, image_url) {
    return ApiService.post("general/constructor/slide/"+slide_id+"/block/"+block_id+"/images/"+user_id+"/"+mode+"/"+id+"/delete", { url: image_url })
  }
}


export const StudentsService = {
  get(params, page = 1) {
    return ApiService.get("instructor/students/get?page="+page, params)
  },
  getHistory(id, page = 1) {
    return ApiService.get(`instructor/students/get/${id}/history?page=${page}`)
  },
  getStudent(id) {
    return ApiService.get("instructor/students/get/"+id)
  },
  add(params) {
    return ApiService.post("instructor/students/add", params, {'Content-Type': 'multipart/form-data'})
  },
  update(id, params) {
    return ApiService.post("instructor/students/"+id+"/update", params, {'Content-Type': 'multipart/form-data'})
  },
  updateNote(id, note) {
    return ApiService.post("instructor/students/"+id+"/note/update", { note: note })
  },
  updateStatus(id, status) {
    return ApiService.post("instructor/students/"+id+"/status/update", { status: status })
  },
  resetPassword(id) {
    return ApiService.post(`instructor/students/${id}/password/reset`)
  },
  delete(id) {
    return ApiService.delete("instructor/students/"+id+"/delete")
  },
  getGroups(params, page = 1) {
    return ApiService.get("instructor/students/group/get?page="+page, params)
  },
  addGroup(params) {
    return ApiService.post("instructor/students/group/add", params)
  },
  updateGroup(id, params) {
    return ApiService.post("instructor/students/group/"+id+"/update", params)
  },
  deleteGroup(id) {
    return ApiService.delete("instructor/students/group/"+id+"/delete")
  },
  resendInvite(id) {
    return ApiService.post(`/instructor/students/${id}/invitation/resend`)
  },
  addSelfStudy(studentId, materialId) {
    return ApiService.post(`/instructor/students/${studentId}/selfstudy/${materialId}/add`)
  },
  removeSelfStudy(studentId, materialId) {
    return ApiService.delete(`/instructor/students/${studentId}/selfstudy/${materialId}/remove`)
  },
  changedSelfStudyType(studentId, type, orderIds) {
    return ApiService.post(`/instructor/students/${studentId}/selfstudy/type`, { type, orderIds })
  },
  makeStudyLessonOpened(studentId, materialId, type) {
    return ApiService.post(`/instructor/students/${studentId}/selfstudy/${materialId}/open`, { type })
  },
  changeSelfStudyOrder(studentId, orderIds, type) {
    return ApiService.post(`/instructor/students/${studentId}/selfstudy/reorder`, { orderIds, type })
  },
  addLightToStudent(studentId, lightId) {
    return ApiService.post(`/instructor/students/${studentId}/light/${lightId}/add`)
  },
  removeLightFromStudent(studentId, lightId) {
    return ApiService.post(`/instructor/students/${studentId}/light/${lightId}/remove`)
  },
  bulkTaskAssignment(params) {
    return ApiService.post(`/instructor/tasks/assignment/bulk`, params)
  },
  examinationUniversal(view, page, search) {
    return ApiService.get(`/instructor/tasks/examination/${view}?page=${page}&q=${search}`)
  },
}


export const UsersService = {
  login(email, password) {
    return ApiService.post("auth/login", { email: email, password: password })
  },
  register(params) {
    return ApiService.post("auth/register", params)
  },
  reset(email) {
    return ApiService.post("auth/reset", { email: email })
  },
  initialPassword(id, hash, password) {
    return ApiService.post("auth/initial/"+id+"/"+hash, { password: password })
  },
  get(loader = true) {
    return ApiService.get("profile/get", {}, { loader: loader })
  },
  update(params) {
    return ApiService.post("profile/update", params)
  },
  updateScheduleSettings(params) {
    return ApiService.post("profile/schedule/settings/update", params)
  },
  changePassword(params) {
    return ApiService.post("profile/password/update", params)
  },
  changeAvatar(params) {
    return ApiService.post("profile/avatar/update", params, {'Content-Type': 'multipart/form-data'})
  },
  saveMetaData(params) {
    return ApiService.post("profile/metadata/update", params)
  },
  loginUnauthorized(lessonId, lessonCode, name) {
    return ApiService.post(`auth/unauthorized/${lessonId}/${lessonCode}`, { name })
  }
}



export const TeachersService = {
  get(params, page = 1, paginate = 24, onlyTeachers = 0) {
    return ApiService.get(`school/teachers/get?page=${page}&paginate=${paginate}&onlyTeachers=${onlyTeachers}`, params)
  },
  add(params) {
    return ApiService.post("school/teachers/add", params, {'Content-Type': 'multipart/form-data'})
  },
  update(id, params) {
    return ApiService.post("school/teachers/"+id+"/update", params, {'Content-Type': 'multipart/form-data'})
  },
  updateStatus(id, status) {
    return ApiService.post("school/teachers/"+id+"/status/update", { status: status })
  },
  resetPassword(id) {
    return ApiService.post(`school/teachers/${id}/password/reset`)
  },
  delete(id) {
    return ApiService.delete("school/teachers/"+id+"/delete")
  }
}



export const PricePoliciesService = {
  get(loader = true) {
    return ApiService.get("instructor/pricepolicies/get", {}, { loader: loader })
  },
  add(params) {
    return ApiService.post("instructor/pricepolicies/add", params)
  },
  update(id, params) {
    return ApiService.post("instructor/pricepolicies/"+id+"/update", params)
  },
  delete(id) {
    return ApiService.delete("instructor/pricepolicies/"+id+"/delete")
  },
}


export const PaymentService = {
  get(params, page = 1) {
    return ApiService.get("instructor/payments/get?page="+page, params)
  },
  getStats(params = {}) {
    return ApiService.get("instructor/payments/statistics/get", params)
  },
  add(params) {
    return ApiService.post("instructor/payments/add", params)
  },
  delete(id) {
    return ApiService.delete("instructor/payments/"+id+"/delete")
  },
}

export const ChatService = {
  sendMessage(lesson_id, message) {
    return ApiService.post("call/message/send", { room_id: lesson_id, message: message })
  },
  getMessages(lesson_id, params) {
    return ApiService.get("call/messages/"+lesson_id+"/get", params)
  },
  getStudentChat(params) {
    return ApiService.get(`call/messages/history`, params)
  },
  getChatBots() {
    return ApiService.get(`instructor/chat_bots/get`)
  },
  createChatBot(params) {
    return ApiService.post(`instructor/chat_bots/create`, params)
  },
  updateChatBot(id, params) {
    return ApiService.post(`instructor/chat_bots/${id}/update`, params)
  },
  deleteChatBot(id) {
    return ApiService.delete(`instructor/chat_bots/${id}/delete`)
  },
  uploadFileForBot(id, params) {
    return ApiService.post(`instructor/chat_bots/${id}/file`, params)
  },
  getBotInstructions(id) {
    return ApiService.get(`instructor/chat_bots/${id}/instructions`)
  },
  saveBotInstructions(id, params) {
    return ApiService.post(`instructor/chat_bots/${id}/instructions`, params)
  },
  // trainOnFile(fileId, ) {
  //
  // },
  sendBotMessage(id, params) {
    return ApiService.post(`call/chat_bots/${id}/messages/send`, params)
  },
  getBotResponse(id, message_id, params) {
    return ApiService.post(`call/chat_bots/${id}/messages/${message_id}/response`, params, {}, { loader: false })
  },
  recordBotMessage(id, params) {
    return ApiService.post(`call/chat_bots/${id}/messages/record`, params)
  },
  getBotMessages(id, oldest_message_id) {
    return ApiService.get(`call/chat_bots/${id}/messages/get?oldest_message_id=${oldest_message_id}`)
  },
}


export const VocabularyService = {
  searchForWord(q, loader = true) {
    return ApiService.get("vocabulary/words/search", { q: q }, { loader: loader })
  },
  getStudentWords(mode, learned, page = 1) {
    return ApiService.get("vocabulary/words?page="+page, { mode: mode, learned: learned })
  },
  getStudentCards(mode, learned, page = 1, amount = 20) {
    return ApiService.get("vocabulary/cards?page="+page+'&amount='+amount, { mode: mode, learned: learned })
  },
  getWordsForMaterial(course_id, material_id) {
    return ApiService.get("instructor/courses/"+course_id+"/materials/"+material_id+"/words/get")
  },
  addWordToStudentStudy(params) {
    return ApiService.post("vocabulary/words/add", params)
  },
  addWordToTeacherStudy(lesson_id, params) {
    return ApiService.post(`call/words/add/${lesson_id}/teacher`, params)
  },
  wordsLearnedByStudent(params) {
    return ApiService.post("vocabulary/words/learned", params)
  },
  cardsLearnedByStudent(params) {
    return ApiService.post("vocabulary/cards/learned", params)
  },
  addWordToMaterial(course_id, material_id, params) {
    return ApiService.post("instructor/courses/"+course_id+"/materials/"+material_id+"/words/add", params)
  },
  deleteWordFromMaterial(course_id, material_id, word_id) {
    return ApiService.delete("instructor/courses/"+course_id+"/materials/"+material_id+"/words/"+word_id+"/delete")
  },
}

export const CardsService = {
  searchForCardWord(q, loader = true) {
    return ApiService.get("vocabulary/cards/search", { q: q }, { loader: loader })
  },
  getWordCardsForMaterial(course_id, material_id) {
    return ApiService.get("instructor/courses/"+course_id+"/materials/"+material_id+"/wordcards/get")
  },
  createWordCard(params) {
    return ApiService.post("vocabulary/cards/create", params, {'Content-Type': 'multipart/form-data'})
  },
  deleteWordCard(card_id) {
    return ApiService.delete("vocabulary/cards/"+card_id+"/delete")
  },
  addWordCardToMaterial(course_id, material_id, word_id) {
    return ApiService.post("instructor/courses/"+course_id+"/materials/"+material_id+"/wordcards/"+word_id+"/add")
  },
  deleteWordCardFromMaterial(course_id, material_id, word_id) {
    return ApiService.delete("instructor/courses/"+course_id+"/materials/"+material_id+"/wordcards/"+word_id+"/delete")
  },
}


export const StudentPageService = {
  home(page = 1) {
    return ApiService.get(`student/home/get?page=${page}`)
  },
  homeSelfStudy(courseId) {
    if (courseId) {
      return ApiService.get(`student/home/get/selfstudy/${courseId}`)
    } else {
      return ApiService.get("student/home/get/selfstudy")
    }
  },
  payments(page = 1) {
    return ApiService.get("student/payments/get?page="+page)
  },
  homeworkDone(params) {
    return ApiService.post("student/homework/done", params)
  },
  homeworkReviewDone(params) {
    return ApiService.post("instructor/students/homework/review/done", params)
  },
  selfStudyFinished(selfStudyLessonId) {
    return ApiService.post(`student/selfstudy/${selfStudyLessonId}/finished`)
  },
  selfStudyReviewed(selfStudyLessonId) {
    return ApiService.post(`instructor/students/selfstudy/${selfStudyLessonId}/reviewed`)
  },
  getReferralLink() {
    return ApiService.get("student/referral")
  },
  getCourses(type = 'light') {
    return ApiService.get(`student/courses/${type}/get`)
  },
  getLightCourseModules(courseId) {
    return ApiService.get(`student/courses/light/modules/${courseId}/get`)
  },
  getLightCourseModuleData(moduleId) {
    return ApiService.get(`student/courses/light/modules/lessons/${moduleId}/get`)
  },
  getLightCourseLessonData(lessonId) {
    return ApiService.get(`student/courses/light/modules/exercises/${lessonId}/get`)
  },
  changeLightProgress(materialId, status, lives_wasted = 0, force) {
    return ApiService.post(`student/light/${materialId}/progress`, { status, lives_wasted, force }, {}, { loader: false })
  },
  resetLightLesson(materialId, wholeLesson = false) {
    return ApiService.post(`student/light/${materialId}/reset`, { wholeLesson })
  },
  // resetLightLesson(materialId, wholeLesson = false) {
  //   return ApiService.post(`student/light/${materialId}/reset`, { wholeLesson })
  // }
}

export const ManagerService = {
  getLesson() {
    return ApiService.get("manager/lesson/get")
  },
  saveLesson(lessonId, params) {
    return ApiService.post(`manager/lesson/${lessonId}/save`, params)
  },

}


export const DictionaryService = {
  initialLoad() {
    return ApiService.get("general/start", {}, { loader: true })
  },
  get() {
    return ApiService.get("general/dictionary/get")
  },
  add(params) {
    return ApiService.post(`/instructor/topics/add`, params)
  },
  generateAudio(text, wordId, slideId, questionUrl, index, audio_config) {
    return ApiService.post(`/general/audio/google`, { text, wordId, slideId, questionUrl, index, audio_config })
  }
}


export const ZoomService = {
  getJWT(params) {
    return ApiService.post("general/zoom/jwt", params)
  },
}

export const JaasService = {
  getJWT(params, lessonId) {
    return ApiService.post(`general/jaas/jwt/${lessonId}`, params)
  },
}

export const ReferralService = {
  getReferralInfo(hash) {
    return ApiService.get(`general/referral/${hash}`)
  },
  submitReferralForm(id, params) {
    return ApiService.post(`general/referral/${id}`, params)
  }
}


