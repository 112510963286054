import Vue from 'vue'
import VueRouter from 'vue-router'
import instructor from '@/middleware/instructor'
import student from '@/middleware/student'
import school from '@/middleware/school'
import manager from '@/middleware/manager'
import methodist from '@/middleware/methodist'
import instructorOrMethodist from '@/middleware/instructorOrMethodist'
import auth from '@/middleware/auth'
import unauthorized from '@/middleware/unauthorized'
import EventBus from '@/services/event-bus'

Vue.use(VueRouter)

const routes = [

    /// --- Authorization --- ///
  {
    path: '/login', name: 'Login',
    meta: { middleware: [unauthorized], layout: "empty" },
    component:() => import('../views/pages/auth/Login.vue'),
  },
  {
    path: '/register', name: 'Register',
    meta: { middleware: [unauthorized], layout: "empty" },
    component:() => import('../views/pages/auth/Register.vue'),
  },
  {
    path: '/password/reset', name: 'ResetPassword',
    meta: { middleware: [unauthorized], layout: "empty" },
    component:() => import('../views/pages/auth/ResetPassword.vue'),
  },
  {
    path: '/password/:id/:hash', name: 'StudentInit',
    meta: { layout: "empty" },
    component:() => import('../views/pages/auth/StudentInit.vue'),
  },

    /// --- Instructor --- ///

  {
    path: '/instructor/home', name: 'Home',
    meta: { middleware: [instructor], layout: "default" },
    component:() => import('../views/pages/instructor/Home.vue'),
  },
  {
    path: '/instructor/schedule', name: 'Schedule',
    meta: { middleware: [instructor], layout: "default" },
    component:() => import('../views/pages/instructor/schedule/Schedule.vue'),
  },
  {
    path: '/instructor/students', name: 'Students',
    meta: { middleware: [instructor], layout: "default" },
    component:() => import('../views/pages/instructor/students/StudentsNew.vue'),
  },
  {
    path: '/instructor/examination', name: 'Examination',
    meta: { middleware: [instructor], layout: "default" },
    component:() => import('../views/pages/instructor/students/Examination.vue'),
  },
  {
    path: '/instructor/students/:id',
    meta: { middleware: [instructor], layout: "default" },
    component:() => import('../views/pages/instructor/students/studentprofile/StudentPage.vue'),
    children: [
      { path: '', redirect: 'history' },
      {
        path: 'history', name: "StudentHistory",
        meta: { middleware: [instructor], layout: "default" },
        component: () => import('../views/pages/instructor/students/studentprofile/StudentHistory.vue')
      },
      {
        path: 'selfstudy', name: "StudentSelfStudy",
        meta: { middleware: [instructor], layout: "default" },
        component: () => import('../views/pages/instructor/students/studentprofile/StudentSelfStudy.vue')
      },
      {
        path: 'payments', name: "StudentPayments",
        meta: { middleware: [instructor], layout: "default" },
        component: () => import('../views/pages/instructor/students/studentprofile/StudentPayments.vue')
      },
      {
        path: 'homework', name: "StudentHomework",
        meta: { middleware: [instructor], layout: "default" },
        component: () => import('../views/pages/instructor/students/studentprofile/StudentHomework.vue')
      },
      {
        path: 'light', name: "StudentLight",
        meta: { middleware: [instructor], layout: "default" },
        component: () => import('../views/pages/instructor/students/studentprofile/StudentLight.vue')
      },
    ]
  },
  {
    path: '/instructor/payments', name: 'Payments',
    meta: { middleware: [instructor], layout: "default" },
    component:() => import('../views/pages/instructor/payments/Payments.vue'),
  },
  {
    path: '/instructor/courses/:id?', name: 'Courses',
    meta: { middleware: [methodist], layout: "default" },
    component:() => import('../views/pages/instructor/courses/Courses.vue'),
  },
  {
    path: '/instructor/courses/:cid/material/:mid', name: 'Material',
    meta: { middleware: [methodist], layout: "default" },
    component:() => import('../views/pages/instructor/courses/Material.vue'),
  },
  {
    path: '/instructor/courses/:cid/material/:mid/slide/:sid', name: 'Slide',
    meta: { middleware: [methodist], layout: "default" },
    component:() => import('../views/pages/instructor/courses/Slide.vue'),
  },
  {
    path: '/instructor/lightcourses', name: 'LightCourses',
    meta: { middleware: [instructorOrMethodist], layout: "default" },
    component:() => import('../views/pages/instructor/courses/LightCourses.vue'),
  },
  {
    path: '/instructor/lightcourses/material/:materialId', name: 'LightCourseConstructor',
    meta: { middleware: [instructorOrMethodist], layout: "default" },
    component:() => import('../views/pages/instructor/courses/LightCourseConstructor.vue'),
  },
  {
    path: '/instructor/settings',
    meta: { middleware: [instructorOrMethodist], layout: "default" },
    component:() => import('../views/pages/instructor/settings/Settings.vue'),
    children: [
      { path: '', redirect: 'profile', name: 'Candidate' },
      {
        path: 'profile', name: "Profile",
        meta: { middleware: [instructorOrMethodist], layout: "default" },
        component: () => import('../views/pages/instructor/settings/Profile.vue')
      },
      {
        path: 'subscription', name: "Subscription",
        meta: { middleware: [instructor], layout: "default" },
        component: () => import('../views/pages/instructor/settings/Subscription.vue')
      },
      {
        path: 'policies', name: "PricePolicies",
        meta: { middleware: [instructor], layout: "default" },
        component: () => import('../views/pages/instructor/settings/PricePolicies.vue')
      },
      {
        path: 'integrations/:integration?', name: "Integrations",
        meta: { middleware: [instructor], layout: "default" },
        component: () => import('../views/pages/instructor/settings/Integrations.vue')
      },
    ]
  },
  {
    path: '/lesson/:id/:code/:homework?/:studentid?', name: 'Lesson',
    meta: { layout: "empty" },
    component:() => import('../views/pages/instructor/Lesson.vue'),
  },
  {
    path: '/unauthorized/lesson/:id/:code', name: 'UnauthorizedToLesson',
    meta: { layout: "empty" },
    component:() => import('../views/pages/general/UnauthorizedToLesson.vue'),
  },
  {
    path: '/selfstudy/lesson/:id/:studentid?', name: 'SelfStudyLesson',
    meta: { middleware: [auth], layout: "empty" },
    component:() => import('../views/pages/instructor/SelfStudyLesson.vue'),
  },
  {
    path: '/material/:id', name: 'MaterialPage',
    meta: { middleware: [auth], layout: "default" },
    component:() => import('../views/pages/instructor/courses/MaterialPage.vue'),
  },


  /// --- Student --- ///

  {
    path: '/student/home', name: 'Student',
    meta: { middleware: [student], layout: "default" },
    component:() => import('../views/pages/student/StudentHomeNew.vue'),
  },
  {
    path: '/student/chat/history', name: 'StudentChatHistory',
    meta: { middleware: [student], layout: "default" },
    component:() => import('../views/pages/student/chat_history/ChatHistory.vue'),
  },
  {
    path: '/student/vocabulary', name: 'StudentVocabulary',
    meta: { middleware: [student], layout: "default" },
    component:() => import('../views/pages/student/vocabulary/StudentVocabulary.vue'),
  },
  {
    path: '/student/courses', name: 'StudentCourses',
    meta: { middleware: [student], layout: "default" },
    component:() => import('../views/pages/student/courses/StudentCourses.vue'),
    children: [
      { path: '', redirect: 'light' },
      {
        path: 'light', name: 'StudentLightCourses',
        meta: { middleware: [student], layout: "default" },
        component:() => import('../views/pages/student/courses/StudentLightCourses.vue'),
      },
      {
        path: 'medium', name: 'StudentMediumCourses',
        meta: { middleware: [student], layout: "default" },
        component:() => import('../views/pages/student/courses/StudentMediumCourses.vue'),
      },
    ]
  },
  {
    path: '/student/courses/light/lesson/:materialId', name: 'StudentLightCourseLessonRoom',
    meta: { middleware: [student], layout: "empty" },
    component:() => import('../views/pages/student/courses/StudentLightCourseLessonRoom.vue'),
  },
  {
    path: '/student/courses/light/:courseId', name: 'StudentLightCourseModules',
    meta: { middleware: [student], layout: "default" },
    component:() => import('../views/pages/student/courses/StudentLightCourseModules.vue'),
  },
  {
    path: '/student/courses/light/:courseId/:moduleId/:lessonId?', name: 'StudentLightCourseLessons',
    meta: { middleware: [student], layout: "default" },
    component:() => import('../views/pages/student/courses/StudentLightCourseLessons.vue'),
  },
  {
    path: '/student/courses/medium/:courseId', name: 'StudentMediumCourseModules',
    meta: { middleware: [student], layout: "default" },
    component:() => import('../views/pages/student/courses/StudentMediumCourseModules.vue'),
  },
  {
    path: '/student/vocabulary/training', name: 'VocabularyTraining',
    meta: { middleware: [student], layout: "default" },
    component:() => import('../views/pages/student/vocabulary/VocabularyTraining.vue'),
  },
  {
    path: '/student/referral', name: 'StudentReferral',
    meta: { middleware: [student], layout: "default" },
    component:() => import('../views/pages/student/settings/StudentReferral.vue'),
  },
  {
    path: '/student/settings',
    meta: { middleware: [student], layout: "default" },
    component:() => import('../views/pages/student/settings/StudentSettings.vue'),
    children: [
      { path: '', redirect: 'profile' },
      {
        path: 'profile', name: "StudentProfile",
        meta: { middleware: [student], layout: "default" },
        component: () => import('../views/pages/instructor/settings/Profile.vue')
      },
      {
        path: 'subscription', name: "StudentSubscription",
        meta: { middleware: [student], layout: "default" },
        component: () => import('../views/pages/student/settings/StudentSubscription.vue')
      }
    ]
  },



  /// --- School --- ///

  {
    path: '/school/home', name: 'School',
    meta: { middleware: [school], layout: "default" },
    component:() => import('../views/pages/instructor/Home.vue'),
  },
  {
    path: '/school/schedule', name: 'SchoolSchedule',
    meta: { middleware: [school], layout: "default" },
    component:() => import('../views/pages/instructor/schedule/Schedule.vue'),
  },
  {
    path: '/school/courses/:id?', name: 'SchoolCourses',
    meta: { middleware: [school], layout: "default" },
    component:() => import('../views/pages/instructor/courses/Courses.vue'),
  },
  {
    path: '/school/lightcourses', name: 'SchoolLightCourses',
    meta: { middleware: [school], layout: "default" },
    component:() => import('../views/pages/instructor/courses/LightCourses.vue'),
  },
  {
    path: '/school/lightcourses/material/:materialId', name: 'SchoolLightCourseConstructor',
    meta: { middleware: [school], layout: "default" },
    component:() => import('../views/pages/instructor/courses/LightCourseConstructor.vue'),
  },
  {
    path: '/school/courses/:cid/material/:mid', name: 'SchoolMaterial',
    meta: { middleware: [school], layout: "default" },
    component:() => import('../views/pages/instructor/courses/Material.vue'),
  },
  {
    path: '/school/courses/:cid/material/:mid/slide/:sid', name: 'SchoolSlide',
    meta: { middleware: [school], layout: "default" },
    component:() => import('../views/pages/instructor/courses/Slide.vue'),
  },
  {
    path: '/school/teachers', name: 'SchoolTeachers',
    meta: { middleware: [school], layout: "default" },
    component:() => import('../views/pages/school/teachers/Teachers.vue'),
  },
  {
    path: '/school/payments', name: 'SchoolPayments',
    meta: { middleware: [school], layout: "default" },
    component:() => import('../views/pages/instructor/payments/Payments.vue'),
  },
  {
    path: '/school/students', name: 'SchoolStudents',
    meta: { middleware: [school], layout: "default" },
    component:() => import('../views/pages/instructor/students/StudentsNew.vue'),
  },
  {
    path: '/school/students/:id',
    meta: { middleware: [school], layout: "default" },
    component:() => import('../views/pages/instructor/students/studentprofile/StudentPage.vue'),
    children: [
      { path: '', redirect: 'history' },
      {
        path: 'history', name: "SchoolStudentHistory",
        meta: { middleware: [school], layout: "default" },
        component: () => import('../views/pages/instructor/students/studentprofile/StudentHistory.vue')
      },
      {
        path: 'history', name: "SchoolStudentSelfStudy",
        meta: { middleware: [school], layout: "default" },
        component: () => import('../views/pages/instructor/students/studentprofile/StudentSelfStudy.vue')
      },
      {
        path: 'payments', name: "SchoolStudentPayments",
        meta: { middleware: [school], layout: "default" },
        component: () => import('../views/pages/instructor/students/studentprofile/StudentPayments.vue')
      },
      {
        path: 'homework', name: "SchoolStudentHomework",
        meta: { middleware: [school], layout: "default" },
        component: () => import('../views/pages/instructor/students/studentprofile/StudentHomework.vue')
      },
    ]
  },
  {
    path: '/school/settings',
    meta: { middleware: [school], layout: "default" },
    component:() => import('../views/pages/instructor/settings/Settings.vue'),
    children: [
      { path: '', redirect: 'profile' },
      {
        path: 'profile', name: "SchoolProfile",
        meta: { middleware: [school], layout: "default" },
        component: () => import('../views/pages/instructor/settings/Profile.vue')
      },
      {
        path: 'subscription', name: "SchoolSubscription",
        meta: { middleware: [school], layout: "default" },
        component: () => import('../views/pages/instructor/settings/Subscription.vue')
      },
      {
        path: 'policies', name: "SchoolPricePolicies",
        meta: { middleware: [school], layout: "default" },
        component: () => import('../views/pages/instructor/settings/PricePolicies.vue')
      },
      {
        path: 'integrations/:integration?', name: "SchoolIntegrations",
        meta: { middleware: [school], layout: "default" },
        component: () => import('../views/pages/instructor/settings/Integrations.vue')
      },
    ]
  },

  /// --- Manager --- ///
  {
    path: '/manager', name: "ManagerPage",
    meta: { middleware: [manager], layout: "default" },
    component: () => import('../views/pages/manager/ManagerPage.vue'),
  },
  { path: '/manager/home', redirect: '/manager' },
  { path: '/methodist/home', redirect: '/instructor/courses' },

  /// --- General --- ///

  {
    path: '/',
    component: () => import('../views/pages/general/Intro.vue'),
  },
  {
    path: '/test', name: 'Test',
    meta: { layout: "empty" },
    component:() => import('../views/pages/Test.vue'),
  },
  {
    path: '/block/upload/:bid/:sid/:uid/:view/:id/:hash', name: 'BlockUpload',
    meta: { layout: "empty" },
    component:() => import('../views/pages/general/BlockUpload.vue'),
  },
  {
    path: '/mediasoup', name: 'Mediasoup',
    meta: { layout: "empty" },
    component:() => import('../views/parts/mediasoup/index.vue'),
  },
  {
    path: '/referral/:hash', name: 'referral',
    component: () => import('../views/pages/general/Referral.vue')
  },
  {
    path: '/sfu/generate', name: 'SFUGenerate',
    meta: { layout: "empty" },
    component: () => import('../views/pages/general/SFUGenerate.vue')
  },
  {
    path: '/turn/test', name: 'TurnTest',
    meta: { layout: "empty" },
    component:() => import('../views/parts/specific/TurnTest.vue'),
  },
  {
    path: '/:path', name: '404',
    component: () => import('../views/pages/general/404.vue')
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
    if(to.name == 'SchoolCourses') {
      return;
    }
    return { x: 0, y: 0 }
  }
})


///--- *** MIDDLEWARE *** ---///

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;
  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  EventBus.$emit('menuclose')
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
    const context = { from, next, router, to }
    const nextMiddleware = nextFactory(context, middleware, 1);
    return middleware[0]({ ...context, next: nextMiddleware });
  }
  return next()
})

export default router
